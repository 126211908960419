import {
  UserPermission,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Group,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { useState } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { useHistory } from "react-router-dom"

export default function AccountTypeSelectionForm() {
  const history = useHistory()
  const [selectedType, setSelectedType] = useState("")

  const userQuery = useUserQuery({ fetchPolicy: "no-cache" })
  const user = userQuery.data?.user

  if (
    user?.roles?.includes(UserPermission.RequestEscrow) ||
    user?.organizations?.length > 0
  ) {
    console.log("Redirecting already onboarded user to role page, [%o]", user)
    window.location.href = `${process.env.REACT_APP_AGENT_DOMAIN}/role`
    return <LoadingOverlay />
  }

  if (!user) {
    return <LoadingOverlay />
  }

  const onContinue = () => {
    const redirectTo =
      selectedType === "business" ? "/business/enroll" : "/agent/register"
    history.push(redirectTo)
  }
  return (
    <Box>
      <Flex direction="column" justify="space-between" px={26} mih="85vh">
        <Box>
          <Space h={38} />
          <Title size="h2" order={2}>
            What type of account do you need?
          </Title>
          <Space h={8} />
          <Text size="lg" color="gray.8">
            Enroll your business to receive and / or request earnest money
            payments. Register as an individual to request earnest money
            payments to enrolled escrow holders.
          </Text>
          <Space h={40} />
          <Group grow>
            <TextInput
              label="Email Address"
              type="email"
              value={user.email}
              disabled
              rightSection={
                <Flex justify="start" align="center">
                  <RiCheckboxCircleFill size={18} />
                  <Space w={4} />
                  <Text size="sm" fw={500}>
                    Verified
                  </Text>
                </Flex>
              }
              rightSectionWidth={72.41 + 12} // Width of above + padding, don't love it but idk how else to do this
              rightSectionProps={{ style: { paddingRight: 12 } }}
            />
          </Group>
          <Space h={40} />
          <Title order={4}>I'd like to...</Title>
          <Space h={8} />
          <Stack>
            <Box
              sx={(theme) => ({
                padding: 12,
                backgroundColor: "#FFFFFF",
                border: "2px solid",
                borderRadius: 8,
                borderColor:
                  selectedType === "business"
                    ? theme.fn.themeColor("green")
                    : theme.fn.themeColor("gray.2"),
              })}>
              <Checkbox
                value="business"
                label="Enroll a business"
                description="Your business will be able to track Escrow Money Payments as well as other real estate transactions"
                checked={selectedType === "business"}
                onChange={(e) => setSelectedType(e.currentTarget.value)}
              />
            </Box>
            <Box
              sx={(theme) => ({
                padding: 12,
                backgroundColor: "#FFFFFF",
                border: "2px solid",
                borderRadius: 8,
                borderColor:
                  selectedType === "agent"
                    ? theme.fn.themeColor("green")
                    : theme.fn.themeColor("gray.2"),
              })}>
              <Checkbox
                value="agent"
                label="Register as an agent"
                description="You will be able to send and manage requests for homebuyers to send money."
                checked={selectedType === "agent"}
                onChange={(e) => setSelectedType(e.currentTarget.value)}
              />
            </Box>
          </Stack>
          <Space h={40} />
          <Box>
            <Title order={4}>Questions?</Title>
            <Space h={8} />
            <Text span size="lg">
              Call us (Mon-Fri 8am-8pm EST):{" "}
            </Text>
            <Text
              size="lg"
              component="a"
              href="tel:+1-864-568-4659"
              color="green">
              1-864-568-4659
            </Text>
          </Box>
        </Box>
      </Flex>
      <PanelFooter>
        <Button size="lg" onClick={onContinue}>
          Continue
        </Button>
      </PanelFooter>
    </Box>
  )
}
